import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import JsBarcode from "jsbarcode";
import { CiEdit } from "react-icons/ci";
import BankUpdate from "./BankUpdate";

function BankDetails(props) {
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancle();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const { id } = props;
  const [itemId1, setItemId1] = useState(id);
  const [selectedTab, setSelectedTab] = useState("overview"); // Initial tab
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [quantity, setQuantity] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const [showEditPage, setEditPage] = useState(false);
  const [editId, setEditId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");

  const handleEditOpen = (id) => {
    setEditId(id);
    setEditPage(true);
    setShowDetails(false);
  };

  const handleCancel = () => {
    setEditPage(false);
    setShowDetails(true);
  };

  const [itemData, setItemData] = useState({});
  const [itemData1, setItemData1] = useState([]);

  // Overview
  useEffect(() => {
    const apiUrl = BASE_URL + `/getBankById/${itemId1}`;
    fetch(apiUrl, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setItemData(data.responseMessage);
      })
      .catch((error) => console.error("Error fetching overview data:", error));
  }, [itemId1]);

  useEffect(() => {
    const postApiUrl = BASE_URL + `/fetchBanks`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setItemData1(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  }, []);

  const handleItemChange = (id) => {
    setItemId1(id);
    setSelectedTab("overview");
  };

  return (
    <>
      {showDetails && (
        <div>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headitems ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  All Banks
                </h5>
                <p className="ml-1 mb-0 text-muted">{itemData1.length} Banks</p>
              </div>
            </div>
            <div className="add-item pr-2"></div>
          </div>
          <div
            className="card border-2 items-border mt-3"
            style={{
              borderRadius: "15px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            {/* <hr /> */}
            <div className="custom-itemdetails-container">
              <div className="left-items custom-scrollbar">
                <div>
                  <div className="text-left" style={{ cursor: "pointer" }}>
                    {itemData1.map((item, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="items-row"
                          onClick={() => {
                            handleItemChange(item.id);
                            setSelectedTab("overview");
                            setQuantity("");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <b
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              // color: " #575757",
                            }}
                          >
                            {item.name}
                          </b>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className="text-muted"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginBottom: "0px",
                              }}
                            >
                              {item.accountType}
                            </p>
                            {/* <span className="fs-1 text-muted">
                              {item.accountType}
                            </span> */}
                          </div>
                        </div>
                        {index < itemData1.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>

              <div className="right-items">
                {/* Top Template Bar */}
                <div className="top-item-bar">
                  <ul className="nav nav-tabs-new d-flex">
                    <h5
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: " #3C7BD4" }}
                    >
                      {itemData.name}
                    </h5>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        style={{
                          borderRadius: "10px",
                          border: "1.4px solid #c1bebe",
                        }}
                        onClick={() => handleEditOpen(itemData.id)}
                      >
                        <CiEdit size={18} /> {/* Set the size to 24 */}
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="custom-item-container custom-scrollbar">
                  {selectedTab === "overview" && (
                    <>
                      <div className="second-col">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className=" mt-3">BANK OVERVIEW</h5>
                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Bank Type :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.accountType || "-"}
                                </label>
                              </div>
                            </div>

                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Bank Name :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.name || "-"}
                                </label>
                              </div>
                            </div>

                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Account Code :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.accountCode || "-"}
                                </label>
                              </div>
                            </div>

                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Currency :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.currency || "-"}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h5 className=" mt-3"></h5>
                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Account Number :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.accountNumber || "-"}
                                </label>
                              </div>
                            </div>
                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  IFSC Code :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.ifsc || "-"}
                                </label>
                              </div>
                            </div>

                            <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label required mg-b-0 text-muted">
                                  Branch Name :
                                </label>
                              </div>
                              <div>
                                <label
                                  className="form-label required mg-b-0"
                                  style={{ color: " #575757" }}
                                >
                                  {itemData.branchName || "-"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEditPage && <BankUpdate id={editId} handleCancle={handleCancel} />}
    </>
  );
}

export default BankDetails;
