import React, { useState, useEffect, useRef } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./header.css";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import "./Navbar.css";
import { AuthURL } from "../../screens/Dashboard/CommonApi";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Search, ChevronDown } from "lucide-react";
import zarp_for_black from "../../asset/images/zarp_for_black.png";
import { Tooltip } from "@mui/material";
import { set } from "date-fns";
// import { color } from "highcharts";

function Navbar({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
    email: "",
  });
  const handleLogOut = () => {
    sessionStorage.removeItem("uniqueId1");
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("refreshToken");
    localStorage.removeItem("uniqueId1");
    localStorage.removeItem("userId");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loginDate");
    handleNavigation("login");
  };

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,
            email: portfolioItem.email,
            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
          handleNavigation("login");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
        handleNavigation("login");
      });
  };
  const intervalIdRef = useRef(null);

  const fetchDataToken = async () => {
    const apiUrl = AuthURL + "/refreshToken";
    const refreshToken1 = localStorage.getItem("refreshToken");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken1 }),
      });

      if (!response.ok) {
        // handleLogOut()
        throw new Error("POST request failed");
      }

      const responseData = await response.json();
      const token = responseData.jwtToken;
      const refreshToken = responseData.refreshToken;

      localStorage.setItem("jwtToken", token);
      localStorage.setItem("refreshToken", refreshToken);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const startFetchingDataInterval = () => {
    const intervalId = setInterval(() => {
      fetchDataToken();
    }, 2 * 60 * 1000);
    return intervalId;
  };
  const checkLoginDate = () => {
    const loginDate = localStorage.getItem("loginDate");
    const currentDate = new Date();
    const formattedCurrentDate = `${String(currentDate.getDate()).padStart(
      2,
      "0"
    )}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${currentDate.getFullYear()}`;

    if (loginDate !== formattedCurrentDate) {
      window.location.reload();
      localStorage.setItem("loginDate", formattedCurrentDate);
    }
  };
  useEffect(() => {
    intervalIdRef.current = startFetchingDataInterval();
    return () => clearInterval(intervalIdRef.current);
  }, []);
  useEffect(() => {
    fetchData();
    checkLoginDate();
  }, []);
  let toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const titleCaseCompanyName = toTitleCase(itemData.companyName);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleProfileClick = () => {
    handleNavigation("profile");
    setDropdownOpen(false);
  };

  const handleSettingClick = () => {
    handleNavigation("Setting");
  };
  const handleOpenNewINV = () => {
    handleNavigation("InvoicesNew");
  };

  const handleOpenNewBill = () => {
    handleNavigation("BillsNew");
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(e.target) &&
        !dropdownButtonRef.current.contains(e.target)
      ) {
        setDropdownOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const options = [
    "Customers",
    "Items",
    "Invoice",
    "Vendor",
    "Banking",
    "Estimates",
    "Expneses",
    "PurchaseBill",
    "DeliveryChallans",
  ];
  const optionToRouteMap = {
    Customers: "Customers",
    Items: "items",
    Invoice: "Invoices",
    Vendor: "vendors",
    Banking: "bankacc",
    Estimates: "Estimates",
    Expneses: "expenses",
    PurchaseBill: "bills",
    DeliveryChallans: "Challans",
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //Searching in navbar input
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.trim() === "") {
      setFilteredOptions([]);
      setIsDropdownOpen(false);
      setSelectedOption(options[0]);
      return;
    }
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(filtered);
    setIsDropdownOpen(filtered.length > 0);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setInputValue(option);
    setFilteredOptions([]);
    setIsDropdownOpen(false);
    const route = optionToRouteMap[option];
    if (route) {
      handleNavigation(route);
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-fixed-top"
        style={{
          width: "100%",
          backgroundColor: "#21263c",
          height: "48px",
          zIndex: "199",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={zarp_for_black}
          alt="User Profile Picture"
          style={{ width: "7%", marginLeft: "40px" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            right: "259px",
          }}
        >
          <div>
            <Tooltip title="Recent Activity" placement="bottom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                style={{ fill: "white", width: "20px", height: "20px" }}
                className="recent-activity-icon"
              >
                <path d="M15.1.2c-.3 0-.5.2-.5.5v2.5C13.2 1.2 10.9.1 8.4 0c-2.1 0-4 .8-5.5 2.2C1.4 3.7.5 5.6.5 7.7c-.1 4.3 3.4 7.9 7.7 8h.1c2.9 0 5.6-1.6 7-4.2.1-.2 0-.5-.2-.7-.2-.1-.5 0-.7.2-1.2 2.3-3.6 3.8-6.2 3.7-3.8-.1-6.8-3.2-6.7-6.9 0-1.8.8-3.5 2.1-4.8 1.3-1.3 3-2 4.9-1.9 2.3 0 4.4 1.2 5.7 3.2l.6.9c.1.2.3.3.6.2.2-.1.4-.3.4-.5V.7c-.2-.3-.4-.5-.7-.5z"></path>
                <path d="M7.8 4.7c-.3 0-.5.2-.5.5V9c0 .2.1.3.2.4l1.9 1.3c.1.1.2.1.3.1.2 0 .3-.1.4-.2.2-.2.1-.5-.1-.7L8.3 8.8V5.2c0-.3-.3-.5-.5-.5z"></path>
              </svg>
            </Tooltip>
          </div>

          <div
            className="search-bar-container"
            style={{
              position: "relative",
              width: isHovered ? "350px" : "300px", // Expands on hover
              background: "#333850",
              borderRadius: "8px",
              padding: "0px",
              border: isHovered ? "1px solid #2098ff" : "1px solid #6c7184", // Border turns blue on hover
              transition: ".3s ease-out",
              left: "20px",
              height: "30px",
            }}
            ref={dropdownRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <form
              className="d-flex align-items-center position-relative"
              style={{ width: "100%" }}
              role="search"
            >
              {/* Dropdown Icon with Vertical Line */}
              <div
                className="dropdown-toggle-icon d-flex align-items-center"
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                  setFilteredOptions(options);
                }}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  left: "35px",
                  top: "48%",
                  color: "#408dfb",
                  transform: "translateY(-50%)",
                  paddingRight: "10px",
                  borderRight: "1px solid #ccc",
                }}
              >
                <ChevronDown size={15} />
              </div>

              {/* Search Input */}
              <input
                className="search-input"
                type="search"
                placeholder={`Search in `}
                value={inputValue}
                aria-label="Search"
                onChange={handleSearchInputChange}
                style={{
                  width: "100%",
                  paddingLeft: "70px", // Space for the dropdown + vertical line
                  paddingRight: "10px", // Space for the search icon
                  background: "transparent",
                  marginBottom: "5px",
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  color: "#f7f7fe",
                  height: "30px",
                }}
              />

              {/* Search Icon */}
              <Search
                className="search-icon"
                size={20}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "48%",
                  transform: "translateY(-50%)",
                  color: "#f7f7fe",
                }}
              />
            </form>

            {/* Dropdown Options - Show on Click */}
            {isDropdownOpen && (
              <ul
                className="dropdown-menu show"
                style={{
                  position: "absolute",
                  top: "45px",
                  left: "0px",
                  background: "white",
                  borderRadius: "5px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  width: "100%", // Same width as search bar
                  zIndex: 1000,
                  padding: "5px 0",
                  maxHeight: "250px",
                  overflowY: "auto",
                  color: "black",
                }}
              >
                {filteredOptions.map((option, index) => (
                  <li key={index} className="dropdown-item">
                    <button
                      className="dropdown-item-button"
                      onClick={() => handleOptionSelect(option)}
                      style={{
                        padding: "",

                        border: "none",
                        width: "100%",
                        textAlign: "left",
                        cursor: "pointer",
                        fontSize: "16px",

                        height: "100%",
                        // margin:"px",
                      }}
                      //  onMouseEnter={(e) => (e.target.style.background = "#007bff",e.target.style.color="white",e.target.style.borderRadius="10px")}
                      //  onMouseLeave={(e) => (e.target.style.background = "white")}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div
          className="navbar-right"
          style={{ height: "48px", marginRight: "10px", alignItems: "center" }}
        >
          <form id="navbar-search" className="navbar-form search-form">
            <button type="button" className="btn btn-default"></button>
          </form>

          <div id="navbar-menu mr-r-12">
            <ul
              className="nav navbar-nav "
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <li className="mr-6" style={{ marginRight: "20px" }}>
                <a
                  href="#"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenNewINV}
                >
                  <AddCircleIcon className="circle-icon" />
                  <span style={{ color: "white", fontSize: "16px" }}>
                    {" "}
                    Invoice
                  </span>
                </a>
              </li>
              <li className="mr-4">
                <a
                  href="#"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenNewBill}
                >
                  <AddCircleIcon className="circle-icon" />
                  <span style={{ color: "white", fontSize: "16px" }}>
                    {" "}
                    Purchase
                  </span>
                </a>
              </li>

              <li className="mr-2">
                <a
                  href="#"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={handleSettingClick}
                >
                  <SettingsIcon
                    className=""
                    style={{ width: "30px", color: "white" }}
                  />{" "}
                </a>
              </li>
              <li className="mr-1">
                <a
                  href="#"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={handleProfileClick}
                >
                  <AccountCircleIcon
                    className=""
                    style={{ width: "30px", color: "white" }}
                  />{" "}
                </a>
              </li>
              <li
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                }}
                className="icon-menu ml-2"
              >
                <h6 className="">
                  {itemData.role}
                  <span>{/* <p>{itemData.email}</p> */}</span>
                </h6>
              </li>

              <li
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  position: "relative",
                }}
                className="ml-4"
              >
                <button
                  type="button"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "#21263c",
                  }}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  ref={dropdownButtonRef}
                >
                  <ArrowDropDownIcon
                    style={{ cursor: "pointer", color: "white" }}
                  />
                  {dropdownOpen && (
                    <div ref={dropdownMenuRef} className="useroptions-dropdown">
                      <ul
                        style={{ listStyleType: "none", margin: 0, padding: 0 }}
                      >
                        <li
                          className="useroptions-option1 cursor-pointer"
                          onClick={handleProfileClick}
                        >
                          Profile
                        </li>
                        <li
                          className="useroptions-option2 cursor-pointer"
                          onClick={handleLogOut}
                        >
                          Log Out
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
