import "./ProfileStyle.css";
import { AES, enc } from "crypto-js";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import Select from "react-select";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CiEdit } from "react-icons/ci";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Alert from "@mui/material/Alert";

function Profile({ navigation }) {
  const [imageChanged, setImageChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State variable to manage showing password
  const [gstNumber, setGstNumber] = useState("");
  const[signatureImage,setSignatureImage]=useState(null);
 
  const[stampImage,setStampImage]=useState(null);
 


  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancelClick = () => {
    handleNavigation("Setting");
  };

  const [startDate, setStartDate] = useState(new Date());
  const [showInvDate, setShowInvDate] = useState(true);

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return "";
  };
  const handleDate = (date) => {
    setStartDate(date);
  };
  const [showSalesModal, setShowSalesModal] = useState(false);
  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);

  const [formData, setFormData] = useState({
    businessRegistered: "YES",
    gstin: "",
    gstRegisteredOn: normalDateFormat(startDate),
    compositionScheme: "",
    reverseCharge: "",
    importExport: "",
    digitalServices: "",
    compositionSchemePercentage: "",
    customDutyTrackingAccount: "",
  });
  const [validationMessage2, setValidationMessage2] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formData.gstin) {
      newValidationMessage += "Please GST Number.\n";
    }

    setValidationMessage2(newValidationMessage);
    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/insertGstSetting", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        ...formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "GST Updated Successfully",
          });
          handleSalesClose();
          getGSTData();
        } else {
          swal({
            icon: "error",
            title: "Failed To Update GST Settting",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const userId = localStorage.getItem("userId");
  const [itemData, setItemData] = useState({
    organizationName: "",
    email: "",
    industry: "",
    businessLocation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    // phone: "",
    fax: "",
    website: "",
    paymentAddress: "",
    primaryContact: "",
    password: "",
    financialPeriod: "",
    companyTitle: "",
    companyId: "",
    taxTitle: "",
    taxId: "",
    panNumber: "",
    companyName: "",
    contact: "",
    addressToReceivePayments: "",
    currency: "",
    language: "",
    haveGstNumber: "",
    id: userId,
    uniqueId: decryptedUniqueId,
  });

  const [validationMessage, setValidationMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const jwtToken = localStorage.getItem("jwtToken");
    const formData = new FormData();
    let newValidationMessage = "";

    if (itemData.contact && !/^\d{10}$/.test(itemData.contact)) {
      newValidationMessage += "Invalid Contact Number. Please enter a 10-digit number.\n";
    }

    if (itemData.email && !/^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/.test(itemData.email)) {
      newValidationMessage += "Invalid Email Address. Please enter a valid email.\n";
    }

    if (itemData.zipcode && !/^\d{5,6}$/.test(itemData.zipcode)) {
      newValidationMessage += "Invalid Zipcode. Please enter a valid 5 or 6-digit zipcode.\n";
    }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    // Append text data to formData
    Object.keys(itemData).forEach((key) => {
      if (itemData[key] !== null) {
        formData.append(key, itemData[key]);
      }
    });

    const submitFormData = (formData) => {
      const apiUrl1 = BASE_URL + "/insertUserProfile";
      const requestOptions1 = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      };
      fetch(apiUrl1, requestOptions1)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Response was non-200");
          }
        })
        .then((data) => {
          swal({
            icon: "success",
            title: "Profile Updated Successfully",
            text: "",
          });
        })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Failed to insert data",
          });
        });
    };
    

    if (imageChanged && image) {
      // Convert data URL to Blob
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("image", blob);
          submitFormData(formData);
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      // Append an empty file blob if image is not provided or not changed
      const emptyFile = new Blob([], { type: "image/jpeg" });
      formData.append("image", emptyFile, "empty.jpg");
      submitFormData(formData);
    }
    if (signatureImage) {
      // Convert data URL to Blob
      fetch(signatureImage)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("signPhoto", blob);
          submitFormData(formData);
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      // Append an empty file blob if image is not provided or not changed
      const emptyFile = new Blob([], { type: "image/jpeg" });
      formData.append("signPhoto", emptyFile, "empty.jpg");
      submitFormData(formData);
    }
    if (stampImage) {
      // Convert data URL to Blob
      fetch(stampImage)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("stampPhoto", blob);
          submitFormData(formData);
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      // Append an empty file blob if image is not provided or not changed
      const emptyFile = new Blob([], { type: "image/jpeg" });
      formData.append("stampPhoto", emptyFile, "empty.jpg");
      submitFormData(formData);
    }
  };


  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const jwtToken = localStorage.getItem("jwtToken");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setImage(
            `https://documents.zarp.software/zarp_images/${portfolioItem.logo}`
          );
          setSignatureImage(
            `https://documents.zarp.software/zarp_images/${portfolioItem.signImage}`
          )
          setStampImage(
            `https://documents.zarp.software/zarp_images/${portfolioItem.stampImage}`
          )

          setItemData({
            organizationName: portfolioItem.organizationName,
            email: portfolioItem.email,
            industry: portfolioItem.industry,
            businessLocation: portfolioItem.businessLocation,
            address1: portfolioItem.address1,
            address2: portfolioItem.address2,
            city: portfolioItem.city,
            state: portfolioItem.state,
            zipcode: portfolioItem.zipcode,
            fax: portfolioItem.fax,
            website: portfolioItem.website,
            paymentAddress: portfolioItem.paymentAddress,
            // primaryContact: portfolioItem.primaryContact,
            password: portfolioItem.password,
            taxId: portfolioItem.taxId,
            financialPeriod: portfolioItem.financialPeriod,
            companyTitle: portfolioItem.companyTitle,
            companyId: portfolioItem.companyId,
            taxTitle: portfolioItem.taxTitle,
            panNumber: portfolioItem.panNumber,
            companyName: portfolioItem.companyName,
            contact: portfolioItem.contact,
            addressToReceivePayments: portfolioItem.addressToReceivePayments,
            currency: portfolioItem.currency,
            language: portfolioItem.language,
            haveGstNumber: portfolioItem.haveGstNumber,
            uniqueId: portfolioItem.uniqueId,
            // logo: portfolioItem.logo,
            id: portfolioItem.id,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  const getGSTData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage[0];
          setGstNumber(Data.gstin);
          setFormData({
            businessRegistered: Data.businessRegistered,
            gstin: Data.gstin,
            gstRegisteredOn: Data.gstRegisteredOn,
            compositionScheme: Data.compositionScheme === "true" ? true : false,
            reverseCharge: Data.reverseCharge === "true" ? true : false,
            importExport: Data.importExport === "true" ? true : false,
            digitalServices: Data.digitalServices === "true" ? true : false,
            compositionSchemePercentage: Data.compositionSchemePercentage,
            customDutyTrackingAccount: Data.customDutyTrackingAccount,
          });
        } else {
          console.error("Failed to fetch GST:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching GST:", error);
      });
  };

  useEffect(() => {
    fetchData();
    getGSTData();
  }, []);

  const handleInputChange = (e) => {
    setItemData({
      ...itemData,
      [e.target.name]: e.target.value,
    });
  };

  const industryOptions = [
    { value: "Agency or Sales House", label: "Agency or Sales House" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Art and Design", label: "Art and Design" },
    { value: "Automotive", label: "Automotive" },
    { value: "Construction", label: "Construction" },
    { value: "Consulting", label: "Consulting" },
    { value: "Consumer Packaged Goods", label: "Consumer Packaged Goods" },
    { value: "Education", label: "Education" },
    { value: "Engineering", label: "Engineering" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Financial Services", label: "Financial Services" },
    {
      value: "Food Services (Restaurants/Fast Food)",
      label: "Food Services (Restaurants/Fast Food)",
    },
    { value: "Gaming", label: "Gaming" },
    { value: "Government", label: "Government" },
    { value: "Health Care", label: "Health Care" },
    { value: "Interior Design", label: "Interior Design" },
    { value: "Internal", label: "Internal" },
    { value: "Legal", label: "Legal" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing", label: "Marketing" },
    { value: "Mining and Logistics", label: "Mining and Logistics" },
    { value: "Non-Profit", label: "Non-Profit" },
    { value: "Publishing and Web Media", label: "Publishing and Web Media" },
    { value: "Real Estate", label: "Real Estate" },
    {
      value: "Retail (E-Commerce and Offline)",
      label: "Retail (E-Commerce and Offline)",
    },
    { value: "Services", label: "Services" },
    { value: "Technology", label: "Technology" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Travel/Hospitality", label: "Travel/Hospitality" },
    { value: "Web Designing", label: "Web Designing" },
    { value: "Web Development", label: "Web Development" },
    { value: "Writers", label: "Writers" },
  ];

  const countryOptions = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    {
      value: "Congo, the Democratic Republic of the",
      label: "Congo, the Democratic Republic of the",
    },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island and McDonald Islands",
      label: "Heard Island and McDonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    {
      value: "Korea, Democratic People's Republic of",
      label: "Korea, Democratic People's Republic of",
    },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
    },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, the former Yugoslav Republic of",
      label: "Macedonia, the former Yugoslav Republic of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States of",
      label: "Micronesia, Federated States of",
    },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    {
      value: "Palestinian Territory, Occupied",
      label: "Palestinian Territory, Occupied",
    },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Réunion", label: "Réunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Barthélemy", label: "Saint Barthélemy" },
    {
      value: "Saint Helena, Ascension and Tristan da Cunha",
      label: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    {
      value: "Saint Martin (French part)",
      label: "Saint Martin (French part)",
    },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic of",
      label: "Tanzania, United Republic of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    {
      value: "Venezuela, Bolivarian Republic of",
      label: "Venezuela, Bolivarian Republic of",
    },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  const optionsFinancial = [
    { value: "April - March", label: "April - March" },
    { value: "January - December", label: "January - December" },
    { value: "July - June", label: "July - June" },
    { value: "October - September", label: "October - September" },
  ];

  const optionsTaxIds = [
    { value: "ABN", label: "ABN" },
    { value: "BN", label: "BN" },
    { value: "CST", label: "CST" },
    { value: "ORG", label: "ORG" },
    { value: "TAX", label: "TAX" },
    { value: "VST", label: "VST" },
  ];

  const optionsCompanyIds = [
    { value: "ACN", label: "ACN" },
    { value: "BN", label: "BN" },
    { value: "CN", label: "CN" },
    { value: "CPR", label: "CPR" },
    { value: "CVR", label: "CVR" },
    { value: "DIW", label: "DIW" },
    { value: "KT", label: "KT" },
    { value: "ORG", label: "ORG" },
    { value: "SEC", label: "SEC" },
  ];

  const stateOptions = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Chandigarh", value: "Chandigarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      value: "Dadra and Nagar Haveli and Daman and Diu",
    },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Delhi", value: "Delhi" },
    { label: "Puducherry", value: "Puducherry" },
  ];

  const [image, setImage] = useState(null);
  const handleDrop1 = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImage(file);
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    handleImage(file);
  };
 
  const handleImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setImageChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageChanged(true);
  };

//Signature Image
  const handleSignatureImageInputChange=(e)=>{
    const file = e.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSignatureImage(reader.result);
        
      };
      reader.readAsDataURL(file);
    }
   
  };

  const handleRemoveSignatureImage=()=>{
    setSignatureImage(null);
    
    ;}

//Stamp Image
  const handleStampImageInputChange=(e)=>{
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setStampImage(reader.result);
      
      };
      reader.readAsDataURL(file);
    }
  
   
  };

  const handleRemoveStampImage=()=>{
    setStampImage(null);
    
    }


  return (
    <Container fluid className="">
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div  
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancelClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  Profile
                </h5>
              </div>
            </div>
          </div>

          <Modal show={showSalesModal} onHide={handleSalesClose}>
            <div class="modal-header d-flex align-items-center pb-2 pt-2">
              <h5 class="modal-title fs-5" id="salespersonHead">
                Update GST
              </h5>
              <button
                type="button"
                className="btn pr-0"
                onClick={handleSalesClose}
              >
                <ion-icon
                  name="close-circle-outline"
                  size="large"
                  style={{ width: "28px" }}
                ></ion-icon>
              </button>
            </div>
            <Modal.Body>
              {validationMessage2 && (
                <Alert severity="error" className="mt-0">
                  {validationMessage2.split("\n").map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </Alert>
              )}{" "}
              <div className="row pt-2">
                <div className="modalsalesinput">
                  <label className="form-label">
                    GST Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Gst Number"
                    name="gstin"
                    type="text"
                    value={formData.gstin}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        gstin: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row pt-4">
                <div className="modalsalesinput">
                  <label className="form-label">
                    <span>GST Registered On </span>
                  </label>
                  {showInvDate ? (
                    <input
                      className="form-control"
                      type="text"
                      value={formData.gstRegisteredOn}
                      onClick={(e) => {
                        setShowInvDate(false);
                        setFormData({
                          ...formData,
                          gstRegisteredOn: normalDateFormat(startDate),
                        });
                        handleDate(startDate);
                      }}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "40px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                      readOnly
                    />
                  ) : (
                    <div className="customDatePickerWidth">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => handleDate(date)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="p-2">
              <div className="modal-bottombtns">
                <button
                  type="button"
                  class="modalbtn-1 btn mt-0"
                  onClick={handleSalesClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="modalbtn-3 btn btn-primary"
                  onClick={handleSaveClick}
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <Card className="card-style">
            {validationMessage && (
              <Alert severity="error" className="mt-3">
                {validationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card.Body>
              <label
                className="text-primary"
                style={{ fontSize: 16, fontWeight: "600" }}
              >
                PROFILE INFO
              </label>

              <Row className="mt-1">
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Organization Logo
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <div
                    onDrop={handleDrop1}
                    onDragOver={(e) => e.preventDefault()}
                    style={{
                      border: "2px dashed #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      width: "200px",
                      height: "200px",
                      position: "relative",
                      marginRight: "20px", // Added margin for spacing
                    }}
                  >
                    {image ? (
                      <>
                        <img
                          src={image}
                          alt="Uploaded"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            borderRadius: "5px",
                          }}
                        />
                        <button
                          onClick={handleRemoveImage}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            padding: "5px 10px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </button>
                      </>
                    ) : (
                      <>
                        <p>Drag & Drop or Click to Browse Image</p>
                        <input
                          type="file"
                          onChange={handleImageInputChange}
                          accept="image/*"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            cursor: "pointer",
                          }}
                        />
                      </>
                    )}
                  </div>
                </Col>
                <Col md="0">
                  <div></div>
                </Col>
                <Col md="3">
                  <div>
                    <p className="" style={{ fontSize: "1.2rem" }}>
                      This logo will appear on the documents (estimates,
                      invoices, etc.) that are created.
                    </p>
                    <p className="" style={{ fontSize: "0.9rem" }}>
                      Preferred Image Size: 240px x 240px @ 72 DPI Maximum size
                      of 1MB.
                    </p>
                  </div>
                </Col>
                <Col md="2">
                  <div></div>
                </Col>
                {formData.businessRegistered === "YES" && (
                  <Col md="3">
                    <div>
                      <label className="form-label required mg-b-0">
                        GST Number :{" "}
                      </label>
                      <span
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "500" }}
                      >
                        {" "}
                        {gstNumber}
                      </span>{" "}
                      <CiEdit
                        style={{ cursor: "pointer" }}
                        size={18}
                        onClick={handleSalesShow}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row className="mt-4">
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required mg-b-0">
                      Organization Name *
                    </label>
                    <Form.Control
                      className=""
                      placeholder="Organization Name"
                      name="companyName"
                      value={itemData.companyName}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>
                {/* <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required">
                      Industry
                    </label>
                    <Select
                      className="mt-0"
                      options={industryOptions}
                      value={industryOptions.find(
                        (option) => option.value === itemData.industry
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "industry",
                            value: selectedOption.value,
                          },
                        })
                      }
                      name="industry"
                      placeholder="Select"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          // border:'none',
                          borderRadius: "8px",
                          padding: "4px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col> */}
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required">
                      Industry
                    </label>
                    <Select
                      options={industryOptions}
                      value={industryOptions.find(
                        (option) => option.value === itemData.industry
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "industry",
                            value: selectedOption.value,
                          },
                        })
                      }
                      placeholder="Select Industry"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "auto",
                          lineHeight: "1.5",  // Adjust line height to prevent text cutoff
                          minHeight: "38px",  // Minimum height to ensure the content is not cut off
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px 8px", // Adjust padding to align text properly
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          padding: "5px 0",  // Add padding to ensure the text is centered
                        }),
                      }}
                    />

                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required mg-b-0">
                      Business Location *
                    </label>
                    <Select
                      options={countryOptions}
                      value={countryOptions.find(
                        (option) => option.value === itemData.businessLocation
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "businessLocation",
                            value: selectedOption.value,
                          },
                        })
                      }
                      name="businessLocation"
                      placeholder="Select Location"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "auto",
                          lineHeight: "1.5",  // Adjust line height to prevent text cutoff
                          minHeight: "38px",  // Minimum height to ensure the content is not cut off
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px 8px", // Adjust padding to align text properly
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          padding: "5px 0",  // Add padding to ensure the text is centered
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <label
                className="text-primary mt-3"
                style={{ fontSize: 16, fontWeight: "600" }}
              >
                ADDRESS
              </label>

              <Row className="mt-1">
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Address Line 1
                    </label>
                    <Form.Control
                      className=""
                      placeholder="Street 1"
                      type="text"
                      name="address1"
                      value={itemData.address1}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Address Line 2
                    </label>
                    <Form.Control
                      className=""
                      placeholder="Street 2"
                      type="text"
                      name="address2"
                      value={itemData.address2}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">City</label>
                    <Form.Control
                      className=""
                      placeholder="City"
                      type="text"
                      name="city"
                      value={itemData.city}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required mg-b-0">State</label>
                    <Select
                      options={stateOptions}
                      value={stateOptions.find(
                        (option) => option.value === itemData.state
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "state",
                            value: selectedOption.value,
                          },
                        })
                      }
                      name="state"
                      placeholder="Select State"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "auto",
                          lineHeight: "1.5",  // Adjust line height to prevent text cutoff
                          minHeight: "38px",  // Minimum height to ensure the content is not cut off
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px 8px", // Adjust padding to align text properly
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          padding: "5px 0",  // Add padding to ensure the text is centered
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required mg-b-0">
                      Zipcode
                    </label>
                    <Form.Control
                      className="form-control"
                      placeholder="zipcode"
                      type="text"
                      name="zipcode"
                      value={itemData.zipcode}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label className="form-label required mg-b-0">
                      Password
                    </label>
                    <div className="password-input">
                      <Form.Control
                        className="form-control"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={itemData.password}
                        onChange={handleInputChange}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",

                          color: "black",
                        }}
                      />
                      <span
                        className="toggle-password-icon"
                        onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on icon click
                      >
                        {showPassword ? (
                          <ion-icon name="eye-off-outline"></ion-icon> // Show eye-off icon when password is visible
                        ) : (
                          <ion-icon name="eye-outline"></ion-icon> // Show eye icon when password is hidden
                        )}
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Conatact Number
                    </label>
                    <Form.Control
                      className="form-control mt-2"
                      placeholder="Contact"
                      type="text"
                      name="contact"
                      value={itemData.contact}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">Fax</label>
                    <Form.Control
                      className="form-control mt-2"
                      placeholder="Fax"
                      name="fax"
                      type="text"
                      value={itemData.fax}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">Website</label>
                    <Form.Control
                      className=""
                      placeholder="Website"
                      name="website"
                      type="text"
                      value={itemData.website}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <label
                className="text-primary mt-3"
                style={{ fontSize: 16, fontWeight: "600" }}
              >
                MORE INFO
              </label>

              <Row className="mt-1">
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Primary Contact Email
                    </label>
                    <Form.Control
                      className=""
                      placeholder="Email"
                      name="email"
                      type="email"
                      value={itemData.email}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      PAN Number
                    </label>
                    <Form.Control
                      className="form-control"
                      placeholder="Pan No"
                      name="panNumber"
                      type="text"
                      value={itemData.panNumber}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Financial Period
                    </label>
                    <Select
                      options={optionsFinancial}
                      value={optionsFinancial.find(
                        (option) => option.value === itemData.financialPeriod
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "financialPeriod",
                            value: selectedOption.value,
                          },
                        })
                      }
                      placeholder="Select Financial Period"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",

                          padding: "5px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="" md="2">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Company Id
                    </label>
                    <Select
                      options={optionsCompanyIds}
                      value={optionsCompanyIds.find(
                        (option) => option.value === itemData.companyId
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "companyId",
                            value: selectedOption.value,
                          },
                        })
                      }
                      placeholder="Company Id"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",

                          padding: "5px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Add Company
                    </label>
                    <Form.Control
                      className=""
                      placeholder="Add Company Title"
                      type="text"
                      name="companyTitle"
                      value={itemData.companyTitle}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="2">
                  <Form.Group>
                    <label className="form-label  mg-b-0">Tax ID</label>
                    <Select
                      options={optionsTaxIds}
                      value={optionsTaxIds.find(
                        (option) => option.value === itemData.taxId
                      )}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "taxId",
                            value: selectedOption.value,
                          },
                        })
                      }
                      placeholder="Tax Id"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",

                          padding: "5px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">Add Tax</label>
                    <Form.Control
                      className=""
                      placeholder="Add Tax Title"
                      type="text"
                      name="taxTitle"
                      value={itemData.taxTitle}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",

                        color: "black",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                      Signature Image
                    </label>
                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label class="form-label  mg-b-0 required">
                     Stamp Image
                    </label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="" md="4">
                <div style={{display:"flex"}}>
                <div
                // onDrop={handleDrop1}
                // onDragOver={(e) => e.preventDefault()}
                style={{
                  border: "2px dashed #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                  width: "200px",
                  height: "200px",
                  position: "relative",
                  marginRight: "20px", // Added margin for spacing
                }}
              >
                {signatureImage ? (
                  <>
                    <img
                      src={signatureImage}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        borderRadius: "5px",
                      }}
                    />
                   
                  </>
                ) : (
                  <>
                    <p>Click To Browse Image</p>
                    <input
                      type="file"
                      onChange={handleSignatureImageInputChange}
                      accept="image/*"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        cursor: "pointer",
                      }}
                    />
                  </>
                )}
              </div>
              <button
              onClick={handleRemoveSignatureImage}
              style={{
                height:"30px",
                padding: "5px 10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Remove
            </button>
            </div>
                </Col>
                <Col className="" md="4">
                <div style={{display:"flex"}}>
                 <div
                    
                    style={{
                      border: "2px dashed #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      width: "200px",
                      height: "200px",
                      position: "relative",
                      marginRight: "20px", // Added margin for spacing
                    }}
                  >
                    {stampImage ? (
                      <>
                        <img
                          src={stampImage}
                          alt="Uploaded"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            borderRadius: "5px",
                          }}
                        />
                        
                      </>
                    ) : (
                      <>
                        <p> Click to Browse Image</p>
                        <input
                          type="file"
                          onChange={handleStampImageInputChange}
                          accept="image/*"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            cursor: "pointer",
                          }}
                        />
                      </>
                    )}
                  </div>
                  <button
                          onClick={handleRemoveStampImage}
                          style={{
                            // position: "absolute",
                            // top: "10px",
                            // right: "10px",
                            padding: "5px 10px",
                            height:"30px",

                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </button>
                        </div>
                </Col>
                </Row>

              {/* <div class="my-2 text-right">
                <button
                  className="btn btn-primary px-2 py-1 mr-2"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary px-2 py-1"
                  style={{ backgroundColor: "var(--primary-color)" }}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Save{"  "}
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
            */}
              <div className="text-right bottom-buttons">
                <button className="btn-1 btn mt-0" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default Profile;
