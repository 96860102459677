import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./BankAccNew.css";
import Alert from "@mui/material/Alert";
import Select from "react-select";

function BankAccNew({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [selectedCurreny, setCurrency] = useState("INR");

  const currencyOptions = [
    { value: "", label: "Select Currency" },
    { value: "INR", label: "INR - Indian Rupee" },
    { value: "AED", label: "AED - United Arab Emirates Dirham" },
    { value: "AUD", label: "AUD - Australian Dollar" },
    { value: "CAD", label: "CAD - Canadian Dollar" },
    { value: "CNY", label: "CNY - Chinese Yuan" },
    { value: "EUR", label: "EUR - Euro" },
    { value: "GBP", label: "GBP - British Pound Sterling" },
    { value: "JPY", label: "JPY - Japanese Yen" },
    { value: "SAR", label: "SAR - Saudi Riyal" },
    { value: "USD", label: "USD - United States Dollar" },
    { value: "ZAR", label: "ZAR - South African Rand" },
  ];

  const handleCurrencyChange = (selectedOption) => {
    setCurrency(selectedOption);
  };

  const [showBank, setShowBank] = useState(true);
  const [showCreditCard, setShowCreditCard] = useState(false);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleBankClick = () => {
    handleNavigation("bankacc");
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure you want to cancel?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleBankClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleBankSelect = () => {
    setShowBank(true);
    setShowCreditCard(false);
  };

  const handleCreditCardSelect = () => {
    setShowCreditCard(true);
    setShowBank(false);
  };

  const [formData, setFormData] = useState({
    primaryAccount: "NO",
    name: "",
    accountCode: "",
    currency: "",
    accountNumber: "",
    bankName: "",
    ifsc: "",
    description: "",
    upiId: "",
    uniqueId: decryptedUniqueId,
    branchName: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedValue = isChecked ? "YES" : "NO";

    setFormData({
      ...formData,
      primaryAccount: updatedValue,
    });
  };
  const [bankData, setBankData] = useState([]);

  const [loading, setLoading] = useState(false);
  const postData = {
    uniqueId: decryptedUniqueId,
  };

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchBanks";
    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          const responseData = await response.json();

          const filteredData = responseData.responseMessage.filter(
            (item) => item.primaryAccount === "YES"
          );
          setBankData(responseData.responseMessage);
          setLoading(false);
        } else {
          console.error("POST request failed");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }, []);

  const [validationMessage, setValidationMessage] = useState("");

  const sendData = async () => {
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    let newValidationMessage = "";

    if (!formData.name) {
      newValidationMessage += "Please enter Account Name.\n";
    }
    if (formData.primaryAccount === "YES" && !formData.upiId) {
      newValidationMessage += "UPI Id is required for primary accounts.\n";
    }
    if (formData.ifsc && !ifscPattern.test(formData.ifsc)) {
      newValidationMessage += "Invalid IFSC Code.\n";
    }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    try {
      setLoading(true);
      // Check if account type (bank or credit card) is selected
      if (!showBank && !showCreditCard) {
        swal({
          icon: "error",
          title: "Account Type Required",
          text: "Please select an account type (Bank or Credit Card).",
        });
        return;
      }

      const requestData = {
        ...formData,
        currency: selectedCurreny?.value || "INR", // Corrected spelling
        accountType: showBank ? "Bank" : "Credit-Card",
      };

      const response = await fetch(BASE_URL + "/insertBank", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to insert data");
      }

      const data = await response.json();
      swal({
        icon: "success",
        title: "Bank Data Added Successfully",
        text: data.message, // Use the message from the server if applicable
      });
      handleBankClick();
    } catch (error) {
      console.error("Error:", error); // Log the error for debugging
      swal({
        icon: "error",
        title: "Network Error",
        text: "An error occurred while communicating with the server.",
      });
    } finally {
      setLoading(false); // Ensure setLoading(false) is called even if there's an error
    }
  };

  const isPrimaryAccountExists = bankData.some(
    (item) => item.primaryAccount === "YES"
  );

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancleClick}
            className="arrow-child"
          />
        </div>
        <div className="newcustomer-head ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            Add Bank / Credit Card
          </h5>
        </div>
      </div>
      <div className="card border-0 my-3">
        {validationMessage && (
          <Alert severity="error" className="mt-3">
            {validationMessage.split("\n").map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Alert>
        )}{" "}
        <div className="pl-4 pr-2 pt-3">
          <h6 className="mb-0" style={{ color: "#086bd5de" }}>
            BANK INFO
          </h6>
          <div className="row pt-3">
            <div className="col-md-2">
              <label className="form-label">
                <span className="text-muted">
                  Select Account Type <span className="valid"> *</span>
                </span>
              </label>
            </div>
            <div className="col-lg-1 pr-0">
              <label className="rdiobox">
                <input
                  className="mx-1"
                  type="radio"
                  name="accountType"
                  id="cus_bus"
                  value="Bank"
                  checked={showBank}
                  onClick={() => handleBankSelect(true)}
                />
                <span>Bank </span>
              </label>
            </div>
            <div className="col-lg-2 pl-0">
              <label className="rdiobox ml-3">
                <input
                  className="mx-1"
                  type="radio"
                  name="accountType"
                  id="cus_indivi"
                  value="Credit-Card"
                  checked={!showBank}
                  onClick={() => handleCreditCardSelect(false)}
                />
                <span>Credit Card</span>
              </label>
            </div>
          </div>

          {showBank && (
            <div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-muted">
                      Account Name <span className="valid"> *</span>
                    </span>
                  </label>
                </div>

                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    id="firstname"
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Account Code</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Code"
                    value={formData.accountCode}
                    name="accountCode"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">
                      Currency <span className="valid"> *</span>
                    </span>
                  </label>
                </div>
                <div className="">
                  <Select
                    className=""
                    options={currencyOptions}
                    defaultValue={currencyOptions.find(
                      (option) => option.value === "INR"
                    )} // Set the default value to INR
                    onChange={handleCurrencyChange}
                    placeholder="Select Currency"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "330px",
                        marginTop: "10px",
                        marginLeft: "19px",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Account Number</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Number"
                    value={formData.accountNumber}
                    name="accountNumber"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-dashed-underline">
                      <span className="text-muted">Bank Name</span>
                    </span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Bank name "
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">IFSC</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add IFSC"
                    value={formData.ifsc}
                    name="ifsc"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">Branch Name</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Branch Name"
                    value={formData.branchName}
                    name="branchName"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Description</span>
                  </label>
                </div>
                <div className="col-lg-7 pl-0" style={{ marginLeft: "19px" }}>
                  <textarea
                    rows="3"
                    cols="20"
                    placeholder="Max. 500 Characters"
                    className=" description-textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div>
                <div className="col-lg-6 offset-lg-2 row pt-3 pl-2">
                  <div className="form-check d-flex align-items-center">
                    <input
                      id="a59745579"
                      className="ember-checkbox ember-view form-check-input"
                      type="checkbox"
                      name="primaryAccount"
                      value={formData.primaryAccount}
                      onChange={handleCheckboxChange}
                    />{" "}
                    <label for="a59745579" className="form-check-label">
                      Make this primary
                    </label>
                  </div>
                </div>
              </div>

              <div>
                {formData.primaryAccount === "YES" && (
                  <div className="row pt-3 d-flex align-items-center">
                    <div className="col-md-2">
                      <label className="form-label required mg-b-0">
                        <span className="text-muted">Enter UPI Id</span>
                      </label>
                    </div>
                    <div className="bankaccinputchild">
                      <input
                        className="form-control"
                        placeholder="Enter Your UPI Id"
                        value={formData.upiId}
                        name="upiId"
                        onChange={handleInputChange}
                        type="text"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="text-right bottom-buttons">
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={sendData}
                >
                  Create
                </button>
              </div>
            </div>
          )}
          {/* Credit Card */}
          {showCreditCard && (
            <div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-muted">
                      Account Name <span className="valid"> *</span>
                    </span>
                  </label>
                </div>

                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Account Code</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Code"
                    name="accountCode"
                    value={formData.accountCode}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">
                      Currency <span className="valid"> *</span>
                    </span>
                  </label>
                </div>
                <div className="">
                  {/* <Select
                    className=""
                    options={currencyOptions}
                    value={selectedCurreny}
                    onChange={handleCurrencyChange}
                    placeholder="Select Currency"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "330px",
                        marginTop: "10px",
                        marginLeft: "19px",

                      }),
                    }}
                  /> */}
                  <Select
                    className=""
                    options={currencyOptions}
                    defaultValue={currencyOptions.find(
                      (option) => option.value === "INR"
                    )} // Set the default value to INR
                    onChange={handleCurrencyChange}
                    placeholder="Select Currency"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "330px",
                        marginTop: "10px",
                        marginLeft: "19px",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Bank Name</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Bank Name"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Description</span>
                  </label>
                </div>
                <div className="col-lg-7 pl-0" style={{ marginLeft: "19px" }}>
                  <textarea
                    rows="4"
                    cols="20"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Max. 500 characters"
                    id="ember786"
                    className="description-textarea "
                  ></textarea>
                </div>
              </div>
              <div className="text-right bottom-buttons">
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={sendData}
                >
                  Add Bank
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BankAccNew;
